<template>
  <div class="add-brand-wrap">
    <div class="header">
      <h3>选择品牌</h3>
      <i
        class="el-icon-close"
        @click="close()"
      ></i>
    </div>
    <div class="content-wrap">
      <div class="brand-table-left">
        <div class="left-head">
          <div class="drop-btn">
            <span
              :class="[tabIdx === 'drop' ? 'active' : '']"
              @click="tabClick('drop')"
            >落位计划品牌</span>
          </div>
          <div class="follow-btn">
            <span
              :class="[tabIdx === 'follow' ? 'active' : '']"
              @click="tabClick('follow')"
            >我关注的品牌</span>
          </div>
          <div class="search-input">
            <div class="search-val">
              <div class="autocomplete-input">
                <el-autocomplete
                  hide-loading
                  reserve-keyword
                  v-model="brandName"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入搜索关键词"
                  @select="handleSelect"
                ></el-autocomplete>
              </div>
              <div
                class="not-search-brand"
                v-if="isToAddBrandBtn"
              > 暂无搜索到此品牌 <span @click="toAddBrand">去添加</span> </div>
            </div>
          </div>
        </div>
        <div class="brand-tree-wrap is-scroll-y">
          <div
            class="task-list"
            v-show="tabIdx === 'drop'"
          >
            <el-tree
              :data="leftList"
              show-checkbox
              node-key="id"
              :props="defaultProps"
              default-expand-all
              ref="treeBrand"
              :highlight-current="true"
              :default-checked-keys="checkedArr"
              @check="handleCheckChange"
            >
            </el-tree>
          </div>
          <!-- :render-content="renderContent" -->
          <div
            class="selected-brand"
            v-show="tabIdx === 'follow'"
          >
            <el-checkbox
              v-for="(item, index) in followList"
              :key="item.gbid"
              :label="item"
              v-model="item.checked"
              @change="changeFollowBrand(item, index)"
            >{{item.brandName}}</el-checkbox>
          </div>
        </div>
      </div>
      <div class="brand-table-center">
        <div class="center-btns">
          <div
            :class="[deleteBrandIds.length ? 'sure' : 'cannot']"
            @click="deltetBrand()"
          >删除</div>
          <!-- <div>到右边<span class="el-icon-arrow-right"></span></div> -->
        </div>
      </div>
      <div class="brand-table-right">
        <div class="right-head">
          <div>已选择品牌</div>
          <div class="right">共选择 <span>{{investmentSelectList.length}}</span> 项</div>
        </div>
        <div class="selected-brand-list is-scroll-y">
          <div class="selected-brand">
            <el-checkbox-group v-model="deleteBrandIds">
              <el-checkbox
                v-for="item in investmentSelectList"
                :key="item.gbid"
                :label="item"
              >{{item.brandName}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div class="info-btn">
      <span
        :class="[isDisabled ? 'isDisabled' : 'sure']"
        @click="sure()"
      >确定</span>
      <span
        class="cancal"
        @click="close()"
      >取消</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Bus from '@/utils/bus.js'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      tabIdx: 'drop',
      brandName: '',
      allBrandList: [],
      leftList: [],
      rightList: [],
      deleteBrandIds: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      checkedArr: [],
      followList: [],
      lastLevelData: [],
      searchList: [],
      brandLibrary: [],
      isToAddBrandBtn: false,
      isDisabled: true,
      searchSelectBrandList: []
    }
  },
  props: {
    projectIdcheck: {
      type: Number
    },
    editType: {
      type: Number
    },
    issueTaskArr: {
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token',
      investmentSelectList: 'investmentSelectList'
    })
  },
  watch: {
    projectIdcheck () {
      this.getBerthBrandList()
      this.getMyFollowFunc()
      this.rightList = []
      this.$store.dispatch('setInvestmentBrandList', this.rightList)
    }
  },
  mounted () {
    if (this.issueTaskArr && this.issueTaskArr.length > 0) {
      this.rightList = this.rightList.concat(this.issueTaskArr)
      var hash = {}
      this.rightList = this.rightList.reduce(function (item, next) {
        /* eslint-disable */
        hash[next.gbid] ? '' : hash[next.gbid] = true && item.push(next)
        /* eslint-enable */
        return item
      }, [])
      this.$store.dispatch('setInvestmentBrandList', this.rightList)
    }
    this.getBerthBrandList()
    this.getMyFollowFunc()
    this.brandLibrary = this.user.confIds.filter(item => {
      return item.id === 164
    })
    Bus.$on('removeAddBrandListBusEmit', () => {
      this.getMyFollowFunc()
      this.getBerthBrandList()
      this.rightList = []
      this.searchSelectBrandList = []
      this.$store.dispatch('setInvestmentBrandList', this.rightList)
      this.$store.dispatch('setGbIdsList', [])
      this.brandName = ''
      this.tabIdx = 'drop'
      this.$refs.treeBrand.setCheckedKeys([])
    })
  },
  methods: {
    getBerthBrandList () {
      this.axios.post(api.getBerthTaskPlan, { projectId: this.projectIdcheck })
        .then((res) => {
          this.leftList = res.data.data.map((item) => {
            const obj = {
              label: item.label,
              id: item.id,
              disabled: false,
              level: 1,
              children: item.children
            }
            obj.children = item.children.map((option) => {
              const obj = {
                label: option.label,
                id: option.id,
                disabled: false,
                level: 2,
                children: option.children
              }
              obj.children = option.children.map((list) => {
                let obj = {}
                obj = list
                obj.label = list.berthNo + list.brandName + (list.isFirst === 1 ? ' ( 首选落位 )' : '')
                obj.value = list.brandId
                obj.floor = option.label
                obj.level = 3
                obj.id1 = item.id
                obj.id2 = list.berthNo
                obj.id = list.berthId + list.gbid
                obj.followName = ''
                obj.followId = ''
                obj.timeVal = []
                obj.planBrands = []
                obj.industry = list.industry
                // obj.disabled = list.approvalStatus === '审批中' ? true : undefined
                obj.disabled = undefined
                return obj
              })
              return obj
            })
            return obj
          })
          this.allBrandList = []
          for (let i = 0; i < this.leftList.length; i++) { // 从tree组件的data处理选中，维护已选择品牌
            const item = this.leftList[i]
            if (item.children && item.children.length) {
              for (let j = 0; j < item.children.length; j++) {
                const item1 = item.children[j]
                if (item1.children && item1.children.length) {
                  for (let k = 0; k < item1.children.length; k++) {
                    const item2 = item1.children[k]
                    if (item2.disabled === undefined) {
                      this.allBrandList.push(item2)
                    }
                  }
                }
              }
            }
          }
          for (let i = 0; i < this.allBrandList.length; i++) {
            const currentObj = this.allBrandList[i]
            for (let j = 0; j < this.allBrandList.length; j++) {
              const item = this.allBrandList[j]
              if (
                i !== j &&
                currentObj.gbid === item.gbid
              ) {
                if ('others' in currentObj) {
                  currentObj.others.push({ id: item.id, id1: item.id1, id2: item.id2 })
                } else {
                  currentObj.others = []
                  currentObj.others.push({ id: item.id, id1: item.id1, id2: item.id2 })
                }
              }
            }
          }
          // setTimeout(() => {
          //   if (this.investmentSelectList && this.investmentSelectList.length > 0) {
          //     const list = this.$refs.treeBrand.getCheckedNodes()
          //     const arr = list.concat(this.investmentSelectList)
          //     this.$refs.treeBrand.setCheckedNodes(arr)
          //     this.handleCheckChange(this.investmentSelectList[0])
          //   }
          // }, 100)
        })
    },
    getMyFollowFunc () {
      this.axios.post(api.myFollowList).then((res) => {
        this.followList = res.data.data.map(item => {
          item.checked = false
          item.followName = ''
          item.followId = ''
          item.timeVal = []
          return item
        })
      })
    },
    querySearchAsync (queryString, cb) {
      if (queryString) {
        const params = {
          brandName: queryString,
          projectId: this.projectIdcheck
        }
        this.axios.get(api.getBrandsAndFocus, { params: params })
          .then((res) => {
            this.searchList = res.data.data.map((item) => {
              if (item.haveTask === 1) {
                item.value = item.brandName + '（已发布招商任务）'
              } else {
                item.value = item.brandName
              }
              item.id = item.berthId + item.gbid
              return item
            })
            if (this.searchList && this.searchList.length > 0) {
              this.isToAddBrandBtn = false
            } else {
              this.isToAddBrandBtn = true
            }
            cb(this.searchList)
          })
      } else {
        this.searchList = []
        this.isToAddBrandBtn = false
        cb(this.searchList)
      }
    },
    handleSelect (item) {
      // console.log(item)
      if (item.haveTask === 1) {
        this.$message({
          type: 'warning',
          message: '该品牌已存在招商任务，无需重复发布。'
        })
        return
      }
      if (item.berthId) {
        item.level = 3
        const list = this.$refs.treeBrand.getCheckedNodes()
        const arr = list.concat([item])
        this.$refs.treeBrand.setCheckedNodes(arr)
        this.searchSelectBrandList.push(item)
        this.rightList = this.searchSelectBrandList
        this.handleCheckChange(item)
      } else {
        if (item.brandId === 0) {
          let falg = true
          if (this.rightList && this.rightList.length > 0) {
            for (const list of this.rightList) {
              if (list.brandName !== item.brandName) {
                falg = false
              } else {
                falg = true
              }
            }
            if (!falg) {
              this.searchSelectBrandList.push(item)
            }
          } else {
            this.searchSelectBrandList.push(item)
          }
          // this.rightList = this.searchSelectBrandList
        } else {
          this.checkEnterpriseTabelBrandDataFunc(item).then((res) => {
            if (res.data > 0) {
              item.gbid = res.data
              let falg = true
              if (this.rightList && this.rightList.length > 0) {
                for (const list of this.rightList) {
                  if (list.brandName !== item.brandName) {
                    falg = false
                  } else {
                    falg = true
                  }
                }
                console.log('item', item)
                if (!falg) {
                  this.searchSelectBrandList.push(item)
                }
              } else {
                console.log('item2', item)
                this.searchSelectBrandList.push(item)
              }
            } else {
              this.$confirm('该品牌将同步到企业品牌库，是否确认？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                showClose: false,
                customClass: 'invitePosition'
              }).then((res) => {
                console.log('1111111')
                this.axios.post(api.exportBrandToEnterpriseBrandTableSingular, {
                  brandId: item.brandId
                })
                  .then((res) => {
                    if (res.data.code === 0) {
                      item.gbid = res.data.data
                      let falg = true
                      if (this.rightList && this.rightList.length > 0) {
                        for (const list of this.rightList) {
                          if (list.brandName !== item.brandName) {
                            falg = false
                          } else {
                            falg = true
                          }
                        }
                        if (!falg) {
                          this.searchSelectBrandList.push(item)
                        }
                      } else {
                        this.searchSelectBrandList.push(item)
                      }
                    } else {
                      this.$message({
                        type: 'warning',
                        message: res.data.msg
                      })
                      this.brandName = ''
                    }
                  })
              }).catch((err) => {
                if (err === 'cancal') {
                  this.brandName = ''
                }
              })
            }
          })
        }
        for (let index = 0; index < this.followList.length; index++) {
          if (item.brandName === this.followList[index].brandName) {
            this.$set(this.followList[index], 'checked', true)
          }
        }
      }
      // if (this.rightList && this.rightList.length > 0) {
      //   console.log(this.searchSelectBrandList)
      //   console.log(this.rightList)
      //   this.rightList = [...this.searchSelectBrandList, ...this.rightList]
      // } else {
      this.rightList = this.searchSelectBrandList
      // }
      this.$store.dispatch('setInvestmentBrandList', this.rightList)
      this.isDisabled = false
    },
    cycle (data) {
      data.children.forEach(item => {
        if (!item.children) {
          this.lastLevelData.push(item)
        } else {
          this.cycle(item)
        }
      })
    },
    handleCheckChange (data, node) {
      if (node && node.checkedKeys.length === 0) {
        const removeGbid = this.allBrandList.map((item) => {
          return item.gbid
        })
        this.rightList = this.rightList.filter((items) => {
          if (!removeGbid.includes(items.gbid)) return items
        })
      }
      // allBrandList 叶子结点列表
      let isChecked = false
      const list = this.$refs.treeBrand.getCheckedNodes()
      const selected = list.filter(item => item.gbid) // 最底层选中的
      const selectedIds = selected.map(item => {
        return item.gbid
      })
      let arr = []
      this.allBrandList.forEach(item => {
        if (selectedIds.indexOf(item.gbid) > -1) {
          arr.push(item)
        }
      })
      if (!data.children) { // 选择最底层的
        selected.forEach(item => {
          if (item.id === data.id) { // 判断当前操作是选中还是取消选中
            isChecked = true
          }
        })
        if (!isChecked) {
          this.rightList = this.rightList.filter((items) => {
            if (!selectedIds.includes(items.gbid)) return items
          })
          if (selectedIds.indexOf(data.gbid) > -1) {
            arr = arr.filter(item => item.gbid !== data.gbid)
          }
        }
      } else { // 选择层级有children的
        this.lastLevelData = []
        this.cycle(data)
        const ids = this.lastLevelData.map(item => item.id)
        selected.forEach(item => {
          if (ids.indexOf(item.id) > -1) {
            isChecked = true
          }
        })
        if (!isChecked) {
          let gbids = this.lastLevelData.map(item => item.gbid)
          this.lastLevelData.forEach(item => {
            if (selectedIds.indexOf(item.gbid) > -1) {
              arr = arr.filter(obj => obj.gbid !== item.gbid)
            }
          })
          gbids = [...new Set(gbids)]
          this.rightList = this.rightList.filter((items) => {
            if (!gbids.includes(items.gbid)) return items
          })
        }
      }
      this.rightList = []
      this.$refs.treeBrand.setCheckedNodes(arr)
      const checkedList = this.$refs.treeBrand.getCheckedKeys()
      const removeList = []
      for (let i = 0; i < this.allBrandList.length; i++) {
        const item = this.allBrandList[i]
        if (checkedList.includes(item.id)) {
          item.planBrands = [{
            floor: item.floor,
            berthId: item.berthId,
            berthNo: item.berthNo,
            isFirst: item.isFirst,
            building: item.building
          }].sort()
          this.rightList.push(item)
        } else {
          removeList.push(item.gbid)
        }
      }
      this.rightList.forEach(element => {
        if ('others' in element) {
          for (let i = 0; i < this.rightList.length; i++) {
            const item = this.rightList[i]
            if (element.id !== item.id && element.gbid === item.gbid) {
              element.planBrands.push(...item.planBrands)
              this.rightList.splice(i, 1)
            }
          }
        }
      })
      if (this.issueTaskArr && this.issueTaskArr.length > 0) {
        this.rightList = this.rightList.concat(this.issueTaskArr)
        var hash = {}
        this.rightList = this.rightList.reduce(function (item, next) {
          /* eslint-disable */
          hash[next.gbid] ? '' : hash[next.gbid] = true && item.push(next)
          /* eslint-enable */
          return item
        }, [])
      }
      if (this.searchSelectBrandList && this.searchSelectBrandList.length > 0) {
        this.rightList = this.rightList.concat(this.searchSelectBrandList)
        var hash1 = {}
        this.rightList = this.rightList.reduce(function (item, next) {
          /* eslint-disable */
          hash1[next.gbid] ? '' : hash1[next.gbid] = true && item.push(next)
          /* eslint-enable */
          return item
        }, [])
      }
      this.$store.dispatch('setInvestmentBrandList', this.rightList)
      this.isDisabled = false
    },
    changeFollowBrand (obj, index) {
      // console.log(obj, index)
      if (obj.checked) {
        this.checkEnterpriseTabelBrandDataFunc(obj).then((res) => {
          if (res.data > 0) {
            obj.gbid = res.data
            this.rightList.push(obj)
          } else {
            this.$confirm('该品牌将同步到企业品牌库，是否确认？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
              showClose: false,
              customClass: 'invitePosition'
            }).then((res) => {
              console.log('22222222')
              this.axios.post(api.exportBrandToEnterpriseBrandTableSingular, {
                brandId: obj.brandId
              })
                .then((res) => {
                  console.log(res)
                  if (res.data.code === 0) {
                    obj.gbid = res.data.data
                    this.$set(this.followList[index], 'checked', obj.checked)
                    this.rightList.push(obj)
                  } else {
                    this.$message({
                      type: 'warning',
                      message: res.data.msg
                    })
                  }
                })
            }).catch((err) => {
              if (err === 'cancel') {
                this.$set(this.followList[index], 'checked', false)
              }
            })
          }
        })
      } else {
        this.rightList = this.rightList.filter((val) => {
          return obj.gbid !== val.gbid
        })
      }
      this.$store.dispatch('setInvestmentBrandList', this.rightList)
      this.isDisabled = false
    },
    deltetBrand () {
      // console.log(this.rightList)
      for (const item of this.deleteBrandIds) {
        this.deleteBrandIds = this.deleteBrandIds.filter((val) => {
          return item.gbid !== val.gbid
        })
        this.searchSelectBrandList = this.searchSelectBrandList.filter((val) => {
          return item.gbid !== val.gbid
        })
        let checkedList = this.$refs.treeBrand.getCheckedKeys() // 当前已选中的id
        checkedList = checkedList.filter(item1 => {
          return item1 !== item.id
        })
        checkedList = checkedList.filter(item1 => {
          return item1 !== item.id2
        })
        checkedList = checkedList.filter(item1 => {
          return item1 !== item.id1
        })
        if (item.others && item.others.length > 0) {
          checkedList = item.others.filter(item1 => {
            return item1 !== item.id1
          })
          checkedList = checkedList.filter(item1 => {
            return item1 !== item.id2
          })
          checkedList = checkedList.filter(item1 => {
            return item1 !== item.id1
          })
        }
        this.followList = this.followList.map(val => {
          if (item.gbid === val.gbid) {
            val.checked = false
          }
          return val
        })
        this.$refs.treeBrand.setCheckedKeys(checkedList)
        this.rightList = this.rightList.filter((val) => {
          return item.gbid !== val.gbid
        })
        this.$store.dispatch('setInvestmentBrandList', this.rightList)
      }
      this.isDisabled = false
    },
    checkEnterpriseTabelBrandDataFunc (item) {
      return new Promise((resolve) => {
        this.axios.post(api.checkEnterpriseTabelBrandData, {
          brand_id: item.brandId,
          brandName: item.brandName
        })
          .then((res) => {
            resolve(res.data)
          })
      })
    },
    sure () {
      if (this.isDisabled) {
        return
      }
      this.rightList = this.rightList.map(item => {
        item.isOpen = false
        item.popover = false
        if (this.editType === 1) {
          item.timeVal = []
        }
        return item
      })
      if (this.rightList && this.rightList.length > 0) {
        if (this.investmentSelectList && this.investmentSelectList.length > 0) {
          const list = JSON.parse(JSON.stringify(this.investmentSelectList))
          this.rightList = this.rightList.concat(list)
          var hash = {}
          this.rightList = this.rightList.reduce(function (item, next) {
            /* eslint-disable */
            hash[next.gbid] ? '' : hash[next.gbid] = true && item.push(next)
            /* eslint-enable */
            return item
          }, [])
        }
        this.$store.dispatch('setInvestmentBrandList', this.rightList)
      } else {
        this.$store.dispatch('setInvestmentBrandList', [])
      }
      // console.log(this.rightList)
      const gbidsList = this.rightList.map((item) => {
        return item.gbid
      })
      this.$store.dispatch('setGbIdsList', gbidsList)
      this.$emit('addBrandEmit')
    },
    renderContent (h, { node, data }) {
      return `
        <span class="custom-tree-node">
          <span>${node.label}</span>
          <span class="btn-name" on-click=${() => this.approval(data, node)}>${node.level === 3 && data.approvalStatus ? '审批中' : ''}</span>
        </span>`
    },
    tabClick (index) {
      this.tabIdx = index
    },
    close () {
      this.$emit('closeAddBrand')
    },
    toAddBrand () {
      if (this.brandLibrary[0].func.indexOf('add') === -1) {
        this.$message({
          type: 'warning',
          message: '抱歉，此账号暂无权限！'
        })
      } else {
        if (document.domain.indexOf('local.winshangdata') > -1) {
          window.open('http://' + document.domain + ':8030/brand/#/checkBrand')
        } else {
          window.open(this.$gaodeDomain + `/omsviews/rent/assets/enterprise?menuCode=11&tokenval=${this.token}&type=checkBrand`)
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.info-btn
  position absolute
  bottom 0
  left 0
  width 100%
  height 76px
  border-top 1px solid #373A43
  padding-top 20px
  padding-left 310px
  background #272930
  span
    display inline-block
    width 130px
    height 36px
    line-height 36px
    text-align center
    color #fff
    border-radius 4px
    margin-right 28px
    cursor pointer
    &.sure
      background-color #f89407
      color #fff
      &:hover
        background-color #DB8307
    &.cancal
      background-color #373A43
    &.isDisabled
      background-color #81591b
      color rgba(255, 255, 255, 0.5)
.add-brand-wrap
  .header
    display flex
    height 70px
    align-items center
    background-color #32343B
    padding 0 24px
    h3
      font-size 16px
      color #fff
      font-weight 800
    .el-icon-close
      margin-left auto
      color #fff
.content-wrap
  padding 30px 24px
  display flex
  height 100vh
  .brand-table-left
    width 414px
    .left-head
      display flex
      position relative
      z-index 111
      .drop-btn
        span
          display inline-block
          width 104px
          height 30px
          color #fff
          line-height 30px
          border 1px solid #fff
          border-radius 2px
          font-size 14px
          text-align center
          cursor pointer
          &.active
            background #F89407
            border-color #F89407
      .follow-btn
        span
          display inline-block
          width 104px
          height 30px
          color #fff
          line-height 30px
          border 1px solid #fff
          border-radius 2px
          font-size 14px
          text-align center
          cursor pointer
          margin 0 16px
          &.active
            background #F89407
            border-color #F89407
      .search-input
        .search-val
          position relative
          .not-search-brand
            border-top 1px solid rgba(255, 255, 255, 0.1)
            position absolute
            left 0
            top 32px
            width 160px
            height 32px
            color #fff
            font-size 12px
            line-height 32px
            padding-left 8px
            background-color #373a43
            span
              color #f89407
              cursor pointer
    .brand-tree-wrap
      margin-top 16px
      border 1px solid #373A43
      border-radius 2px
      height calc(100% - 230px)
      overflow-y auto
      padding-bottom 20px
      position relative
      z-index 11
  .brand-table-center
    width 145px
    text-align center
    .center-btns
      margin-top 164px
      >div
        width 82px
        height 36px
        background-color #81591b
        margin auto
        margin-bottom 42px
        color rgba(255, 255, 255, 0.5)
        line-height 36px
        text-align center
        border-radius 2px
        font-size 14px
        cursor pointer
        &.sure
          background-color #f89407
          color #fff
          &:hover
            &:hover
              background-color #DB8307
  .brand-table-right
    width 300px
    .right-head
      display flex
      height 32px
      line-height 46px
      div
        color #B2B3BD
        font-size 14px
      .right
        margin-left auto
        span
          color #F89407
    .selected-brand-list
      margin-top 16px
      border 1px solid #373A43
      border-radius 2px
      height calc(100% - 230px)
      overflow-y auto
      padding-bottom 16px
</style>
