<template>
  <div class="brand-follow-wrap">
    <div class="header">
      <h3>{{editType === 1 ? '发布招商任务' : '添加品牌跟进'}}</h3>
      <i
        class="el-icon-close"
        @click="close()"
      ></i>
    </div>
    <div class="content is-scroll-y">
      <div class="select-project">
        <div class="info-title">招商项目</div>
        <div class="info-item">
          <div class="title">项目名称 <span>*</span> </div>
          <div class="cascader-style height36-input">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="projectIdcheck"
              size='small'
              placeholder="请选择"
              @change="changeProject"
            >
              <el-option
                v-for="item in projectList"
                :key="item.id"
                :label="item.projectName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="brand-wrap">
        <div class="info-title">品牌列表
          <span @click="addBrandBtn">添加品牌</span>
        </div>
      </div>
      <div
        class="brand-list"
        v-if="invesOrFollowBrandList && invesOrFollowBrandList.length > 0"
      >
        <div
          class="brand-item-info"
          v-for="(item, idx) in invesOrFollowBrandList"
          :key="item.gbid"
        >
          <div class="brand-item">
            <div class="brand-logo">
              <img
                :src="item.brandLogo?item.brandLogo:'https://jscss.winshangdata.com/3.0/list/images/no-logo.png'"
                alt=""
              >
            </div>
            <div class="brand-name">
              <div
                class="name"
                @click="toDetails(item)"
              >{{item.brandName}}</div>
              <div class="industry">{{item.typeNames}} <span>{{item.brandClass}}</span> </div>
            </div>
            <div
              class="release-item"
              v-if="editType === 1"
            >
              <div class="height36-input">
                <el-date-picker
                  popper-class="black-el-date-picker"
                  v-model="item.timeVal"
                  type="daterange"
                  range-separator="~"
                  start-placeholder="招商时效开始"
                  end-placeholder="招商时效结束"
                  size="small"
                >
                </el-date-picker>
              </div>
            </div>
            <div
              class="add-brand-follow-input"
              v-if="editType === 2"
            >
              <div
                class="follow-brand-input"
                style="margin-right: 24px;"
              >
                <el-select
                  popper-class="w-block-select-down"
                  v-model="item.followState"
                  size='small'
                  @change="followChange"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="follow-brand-input">
                <el-date-picker
                  popper-class="black-el-date-picker"
                  v-model="item.lastFollowTime"
                  type="date"
                  placeholder="选择日期时间"
                  align="right"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="font-btn">
              <span
                :class="[item.isOpen ? 'icon-showMore' : 'icon-showLess']"
                class="icon-font icon-showMore"
                @click="isOpenBerth(item)"
              ></span>
            </div>
          </div>
          <div
            class="berth-item"
            v-if="item.isOpen"
          >
            <div class="berth-info">
              <div class="title">意向落位铺位</div>
              <div class="berth-list">
                <div
                  class="item"
                  v-for="(list, index) in item.berths"
                  :key="index"
                  :class="[list.isFirst === 1 || list.isFirst === 0 ? 'cannot-delete' : 'yes-delete']"
                >{{list.building}}/{{list.berthNo}}
                  <span
                    v-if="list.isFirst === 2"
                    @click="deleteBerth(item, list)"
                    class="el-icon-close"
                  ></span>
                </div>
                <el-popover
                  popper-class="black-popover w-input-sty"
                  placement="top"
                  trigger="click"
                  v-model="item.popover"
                >
                  <h3>添加意向铺位</h3>
                  <div class="height36-input add-cascader">
                    <el-cascader
                      v-model="selectFloor"
                      popper-class="w-block-select-down yellow-select-panel"
                      :options="filterFloorArr"
                      @change="changeFloor"
                      style="height: 32px;"
                      filterable
                      clearable
                      size="small"
                    ></el-cascader>
                  </div>
                  <div class="footer">
                    <span
                      class="button-basic button-primary"
                      :class="{'disabled-sty': selectFloor && selectFloor.length === 0}"
                      @click="selectBerth(item, idx)"
                    >确定</span>
                    <span
                      class="button-basic button-low"
                      @click="addBerthClick(0, idx)"
                    >取消</span>
                  </div>
                  <div
                    slot="reference"
                    class="icon-font icon-add2"
                    @click="addBerthClick(1, idx)"
                  ></div>
                </el-popover>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="brand-zero"
        v-else
      >
        <div>
          <img
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>未添加任何品牌</p>
        </div>
      </div>
      <div
        class="follow-user-name"
        v-if="editType === 1"
      >
        <div class="info-title">品牌跟进</div>
        <div class="info-item">
          <div class="title">品牌跟进人 <span>*</span> </div>
          <div class="cascader-style height36-input">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="followId"
              size='small'
              placeholder="请选择"
              @change="selectFollowUser"
              @focus="selectFocus"
            >
              <el-option
                v-for="item in followList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="info-btn">
      <span
        :class="[isDisabled ? 'sure' : 'isDisabled']"
        @click="sure()"
      >保存</span>
      <span
        class="cancal"
        @click="close()"
      >取消</span>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import Bus from '@/utils/bus.js'
export default {
  data () {
    return {
      isDisabled: false,
      projectList: [],
      followList: [],
      followId: '',
      projectIdcheck: '',
      filterFloorArr: [],
      selectFloor: [],
      showPopover: [false, false],
      selectBerthInfo: {},
      deleteBerthList: [],
      followUserName: '',
      statusList: [{
        label: '待跟进',
        value: '待跟进'
      }, {
        label: '已联系',
        value: '已联系'
      }, {
        label: '已看场',
        value: '已看场'
      }, {
        label: '已报价',
        value: '已报价'
      }, {
        label: '已预定',
        value: '已预定'
      }, {
        label: '已签约',
        value: '已签约'
      }, {
        label: '已搁置',
        value: '已搁置'
      }, {
        label: '无意向',
        value: '无意向'
      }],
      modulesList: [],
      invesOrFollowBrandList: []
    }
  },
  props: {
    projectId: {
      type: Number
    },
    editType: {
      type: Number
    },
    issueTaskArr: {
      type: Array
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token',
      investmentSelectList: 'investmentSelectList',
      gbidsList: 'gbidsList'
    })
  },
  watch: {
    projectIdcheck (newVal) {
      this.getFollow()
    },
    invesOrFollowBrandList (newVal, oldVal) {
      if (this.editType === 2) {
        if (newVal.length > 0) {
          this.isDisabled = true
        }
      } else {
        if (newVal.length > 0 && this.followId) {
          this.isDisabled = true
        }
      }
    },
    gbidsList (newVal) {
      this.getIntentionBerths(newVal)
    }
  },
  mounted () {
    this.projectIdcheck = this.projectId
    this.getProjectList()
    this.getFollow()
    this.getFilterFloorFunc()
    this.getModularListFunc()
    if (this.issueTaskArr && this.issueTaskArr.length > 0) {
      const gbid = this.issueTaskArr.map((item) => {
        return item.gbid
      })
      const gbids = gbid.concat(this.gbidsList)
      if (gbids && gbids.length > 0) {
        this.getIntentionBerths(gbids)
      }
    } else {
      if (this.gbidsList && this.gbidsList.length > 0) {
        this.getIntentionBerths(this.gbidsList)
      }
    }
  },
  methods: {
    getIntentionBerths (gbids) {
      const params = {
        gbids: gbids.join(','),
        projectId: this.projectIdcheck
      }
      this.axios.get(api.getIntentionBerthByGbid, { params: params })
        .then((res) => {
          this.invesOrFollowBrandList = res.data.data
        })
    },
    closeClick (e) {
      console.log(e.target)
    },
    getModularListFunc () { // 获取工作台的菜单列表，这里用于判断有无权限调整相关页面
      this.axios.post(api.getModularList)
        .then((res) => {
          this.modulesList = res.data.data.filter(item => {
            return item.id === 12
          })
        })
    },
    toDetails (item) {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + this.token + '&brandId=' + item.brandId + '&gbid=' + item.gbid + '&brandName=' + item.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
    },
    getProjectList () {
      this.axios.post(api.getBerthProjectList).then(res => {
        this.projectList = res.data.data
      })
    },
    getFollow () {
      this.axios.post(api.getFollowList, { projectId: this.projectId, isShow: 1 })
        .then((res) => {
          this.followList = res.data.data.map((item) => {
            const obj = {
              value: item.userId,
              label: item.userName
            }
            return obj
          })
        })
    },
    followChange (type) {
      if (type === '已签约') {
        if (this.modulesList[0].flag) {
          if (this.modulesList[0].userFlag) {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/add/mainBodyInfo?menuCode=12&tokenval=' + this.token, '_blank')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      } else if (type === '已预定') {
        if (this.modulesList[0].flag) {
          if (this.modulesList[0].userFlag) {
            window.open(this.$gaodeDomain + '/omsviews/rent/contract/intentionContract/add/mainBodyInfo?menuCode=12&tokenval=' + this.token, '_blank')
          } else {
            this.$message({
              type: 'warning',
              message: '抱歉，此账号暂无权限！'
            })
          }
        } else {
          this.$message({
            type: 'warning',
            message: '抱歉，集团暂未开通此应用模块！'
          })
        }
      }
    },
    getFilterFloorFunc (id) {
      this.axios.post(api.getWholeFloorData, { projectId: this.projectId })
        .then((res) => {
          this.filterFloorArr = res.data.data
        })
    },
    changeFloor (val) {
      if (val) {
        this.selectBerthInfo = this.filterFloorArr[0].children.filter(item => item.value === this.selectFloor[1])[0].children.find(x => x.value === this.selectFloor[2])
      } else {
        this.selectBerthInfo = {}
      }
    },
    addBerthClick (type, idx) {
      this.idx = idx
      if (type === 1) {
        this.$set(this.showPopover, idx, true)
      } else {
        this.$set(this.showPopover, idx, false)
        this.invesOrFollowBrandList = this.invesOrFollowBrandList.map((item) => {
          item.popover = false
          return item
        })
        this.selectFloor = []
      }
    },
    selectFocus () {
      this.invesOrFollowBrandList = this.invesOrFollowBrandList.map((item) => {
        item.popover = false
        return item
      })
    },
    selectBerth (val, idx) {
      let flag = true
      for (const item of val.berths) {
        if (item.berthId.includes(this.selectFloor[2])) {
          flag = false
        }
      }
      if (flag) {
        const arr = []
        const obj = {}
        obj.building = this.selectFloor[0]
        obj.berthNo = this.selectBerthInfo.label
        obj.floor = this.selectBerthInfo.label
        obj.berthId = this.selectFloor[2]
        obj.isFirst = 2
        arr.push(obj)
        this.invesOrFollowBrandList = this.invesOrFollowBrandList.map((item) => {
          if (item.gbid === val.gbid) {
            if (!item.berths) {
              item.berths = arr
            } else {
              item.berths = item.berths.concat(arr)
            }
            item.popover = false
          }
          return item
        })
        this.selectFloor = []
      } else {
        this.$message({
          type: 'warning',
          message: '该品牌已有该意向落位铺位，请选择其它铺位添加！'
        })
      }
    },
    deleteBerth (val, delItem) {
      this.deleteBerthList.push({
        berthId: delItem.berthId,
        brandId: val.brandId,
        gbid: val.gbid
      })
      this.invesOrFollowBrandList = this.invesOrFollowBrandList.map((item) => {
        if (item.gbid === val.gbid) {
          item.berths = item.berths.filter(options => {
            return options.berthId !== delItem.berthId
          })
        }
        return item
      })
    },
    changeProject (val) {
      this.$emit('selectProjectIdEmit', val)
      this.$store.dispatch('setInvestmentBrandList', [])
      this.$store.dispatch('setGbIdsList', [])
      this.followId = ''
      this.followUserName = ''
    },
    selectFollowUser (val) {
      if (this.followId && this.invesOrFollowBrandList.length > 0) {
        this.isDisabled = true
      }
      const obj = this.followList.find(item => {
        return item.value === val
      })
      this.followUserName = obj.label
    },
    isOpenBerth (val) {
      this.invesOrFollowBrandList = this.invesOrFollowBrandList.map((item) => {
        if (item.gbid === val.gbid) {
          item.isOpen = !item.isOpen
        }
        return item
      })
    },
    addBrandBtn () {
      this.$emit('showAddBrand')
    },
    sure () {
      let flag = true
      if (!this.invesOrFollowBrandList.length) return
      if (this.editType === 1 && !this.isDisabled) return
      if (this.editType === 1 && !this.followId) {
        this.$message({
          type: 'warning',
          message: '请选择品牌跟进人！'
        })
        return
      }
      const addBerthTaskDTOs = {}
      const data = []
      for (let i = 0; i < this.invesOrFollowBrandList.length; i++) {
        const obj = {}
        obj.brandId = this.invesOrFollowBrandList[i].brandId
        obj.gbid = this.invesOrFollowBrandList[i].gbid
        obj.brandName = this.invesOrFollowBrandList[i].brandName
        if (this.editType === 1) {
          if (this.invesOrFollowBrandList[i].timeVal && this.invesOrFollowBrandList[i].timeVal.length > 0) {
            obj.planStartTime = dayjs(this.invesOrFollowBrandList[i].timeVal[0]).format('YYYY-MM-DD')
            obj.planFinishTime = dayjs(this.invesOrFollowBrandList[i].timeVal[1]).format('YYYY-MM-DD')
          }
        } else {
          if (this.invesOrFollowBrandList[i].followState === '已签约' || this.invesOrFollowBrandList[i].followState === '已预定') {
            flag = false
          }
          obj.taskState = this.invesOrFollowBrandList[i].followState || ''
          if (this.invesOrFollowBrandList[i].lastFollowTime) {
            obj.lastFollowTime = dayjs(this.invesOrFollowBrandList[i].lastFollowTime).format('YYYY-MM-DD')
          } else {
            obj.lastFollowTime = ''
          }
        }
        obj.berthIntention = []
        if (this.invesOrFollowBrandList[i].berths && this.invesOrFollowBrandList[i].berths.length > 0) {
          for (let j = 0; j < this.invesOrFollowBrandList[i].berths.length; j++) {
            const berth = {}
            berth.berthId = this.invesOrFollowBrandList[i].berths[j].berthId
            berth.floor = this.invesOrFollowBrandList[i].berths[j].floor
            berth.building = this.invesOrFollowBrandList[i].berths[j].building
            obj.berthIntention.push(berth)
          }
        }
        data.push(obj)
      }
      if (this.editType === 2) {
        if (!flag) {
          this.$message({
            type: 'warning',
            message: '添加品牌为已签约或者已预定请在新开页面中添加！'
          })
          return
        }
      }
      addBerthTaskDTOs.projectId = this.projectIdcheck
      addBerthTaskDTOs.addBerthTaskDTOs = data
      if (this.deleteBerthList && this.deleteBerthList.length > 0) {
        addBerthTaskDTOs.deleteIntentions = this.deleteBerthList
      }
      addBerthTaskDTOs.type = 1
      addBerthTaskDTOs.followUserId = this.followId
      addBerthTaskDTOs.followUserName = this.followUserName
      let apiStr = ''
      if (this.editType === 1) {
        apiStr = api.fabuBerthTaskfive
      } else {
        apiStr = api.addBrandFollowUp
      }
      this.axios.post(apiStr, addBerthTaskDTOs)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功!'
            })
            Bus.$emit('removeAddBrandListBusEmit')
            this.$emit('releaseBrandSuccessEmit')
          }
        })
    },
    close () {
      this.$emit('hideBrandFollowEmit')
    }
  }
}
</script>

<style lang="stylus" scoped>
.info-title
  position relative
  font-size 16px
  padding-left 7px
  color #fff
  &:after
    content ''
    display inline-block
    position absolute
    left 0px
    top 5px
    width 2px
    height 14px
    background #F89407
    z-index 11
  span
    float right
    color #F89407
    font-size 14px
    cursor pointer
.brand-follow-wrap
  height 100%
  .header
    display flex
    height 70px
    align-items center
    background-color #32343B
    padding 0 24px
    h3
      font-size 16px
      color #fff
      font-weight 800
    .el-icon-close
      margin-left auto
      color #fff
.content
  padding 32px 24px 60px 24px
  margin-bottom 160px
  height calc(100% - 190px)
  max-height 800px
  overflow-y auto
  .select-project, .follow-user-name
    .info-item
      display flex
      height 36px
      align-items center
      margin-top 14px
      padding-left 7px
      .title
        color #B2B3BD
        font-size 14px
        margin-right 6px
        span
          color #EE2D41
    .berth-info
      display flex
      padding-left 78px
      margin-top 16px
      .area
        font-size 14px
        color #808191
      .status
        font-size 14px
        color #fff
        margin-left 24px
  .brand-wrap
    margin-top 32px
    .info-title
      margin-bottom 16px
  .follow-user-name
    margin-top 32px
  .brand-list
    .brand-item-info
      background-color #1E1E23
      margin-bottom 24px
      .brand-item
        height 89px
        display flex
        align-items center
        .brand-logo
          width 57px
          height 57px
          margin 0 12px 0 24px
          img
            width 100%
            height 100%
            border-radius 2px
        .brand-name
          min-width 220px
          .name
            font-size 16px
            font-weight bold
            color #FFFFFF
            margin-bottom 10px
            text-decoration underline
            cursor pointer
            &:hover
              color #F89407
          .industry
            margin-top 10px
            font-size 12px
            font-weight 400
            color #B2B3BD
            span
              margin-left 20px
        .font-btn
          margin-left auto
          margin-right 24px
          cursor pointer
          &:hover
            span:before
              color #f89407
        .release-item
          margin-left 10px
          margin-bottom 20px
        .add-brand-follow-input
          display flex
          margin-bottom 20px
      .berth-item
        border-top 1px solid #373A43
        min-height 86px
        .berth-info
          padding 24px 24px 1px 24px
          display flex
          .title
            font-size 14px
            font-weight 400
            color #B2B3BD
            line-height 36px
          .berth-list
            display flex
            flex-wrap wrap
            .item
              margin-left 24px
              position relative
              margin-bottom 24px
              &.cannot-delete
                padding 0 8px
                height 36px
                line-height 34px
                border 1px solid #F89407
                text-align center
                border-radius 2px
                font-size 12px
                font-weight 500
                color #F89407
              &.yes-delete
                padding 0 35px 0 10px
                height 36px
                line-height 36px
                background #373A43
                border-radius 2px
                font-size 12px
                font-weight 500
                color #fff
              .el-icon-close
                position absolute
                right 10px
                top 13px
            .icon-add2
              height 36px
              width 36px
              line-height 36px
              text-align center
              background-color #373A43
              margin-left 24px
              margin-bottom 24px
              cursor pointer
              color #fff
.info-btn
  position absolute
  bottom 0
  left 0
  width 100%
  height 76px
  border-top 1px solid #373A43
  padding-top 20px
  padding-left 286px
  background #272930
  span
    display inline-block
    width 130px
    height 36px
    line-height 36px
    text-align center
    color #fff
    background #F89407
    border-radius 4px
    margin-right 28px
    cursor pointer
    &.sure
      &:hover
        background-color #DB8307
    &.cancal
      background-color #373A43
    &.isDisabled
      background-color #81591b
      color rgba(255, 255, 255, 0.5)
.brand-zero
  width 100%
  height 175px
  background-color #1E1E23
  border-radius 2px
  text-align center
  display flex
  align-items center
  justify-content center
  img
    width 137px
    height 99px
  p
    margin-top 8px
    color #808191
    font-size 14px
    font-weight 500
</style>
